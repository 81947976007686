//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Search from '@/components/Search'
export default {
    components: {Search},
    data() {
        return {
			uniacid:0,
            orderData: null,
            paymentList: [],
            pay_type: '',
            dialogVisible: false
        };
    },
    created() {
		if(this.$route.query.order_id){
			 this.getOrderInfo(this.$route.query.order_id)
		}else{
			 this.getOrderInfo(this.$route.params.order)
		}
       
    },
    methods: {
        async getOrderInfo(order_sn) {
            const res = await this.$http.get('/order/detail', {order_sn})
            if (res.code !== 0) {
                return this.$message.error(res.message); 
            }
            let data = res.data
            this.orderData = data
        },
        //選擇支付方式
        async getPaymentList() {
			
			 this.uniacid =window.localStorage.getItem('uniacid');
			
            const res = await this.$http.get('/pay', {
                order_sn: this.orderData.basic.data.field.order_sn,v:5,uniacid:this.uniacid
            })
            if (res.code !== 0) {
                return this.$message.error(res.message);
            }
            this.paymentList = res.data.list
            this.dialogVisible = true
        },

        async pay() {
			console.log('paytype',this.pay_type);
		 
            if(!this.pay_type){
                return this.$message.error('請選擇支付方式')
            }
			let gourl1="https://w7.khshop.online/app/index.php?i="+this.uniacid+"&c=entry&m=yumi_jinxiaocun&do=api_payasia_pc_wechat&pay_type="+this.pay_type
			    gourl1+="&amount="+this.orderData.basic.data.field.order_amount
			    gourl1+="&order_sn="+this.orderData.basic.data.field.order_sn
			console.log('payasia....');
			
			if(this.pay_type=='payasia'){
				console.log('payasia....');
				window.location.href= gourl1
				return 
			}
			if(this.pay_type=='paypal'){
				let gourl="https://w7.khshop.online/app/index.php?i="+this.uniacid+"&c=entry&m=yumi_jinxiaocun&do=paypal"
				    gourl+="&price="+this.orderData.basic.data.field.order_amount
				    gourl+="&tcid="+this.orderData.basic.data.field.order_sn
				console.log('payasia....');
				window.location.href= gourl
				return 
			}
			if(this.pay_type=='payasiazfb'){
				console.log('payasia....');
				window.location.href= gourl1
				return  
			}		
			if(this.pay_type=='payasiawechat'){
			
				window.location.href= gourl1
				return 
			}
            const res = await this.$http.post('/pay/'+this.pay_type, {
                order_sn: this.orderData.basic.data.field.order_sn
            })
            if(res.code == 0){
                 this.$message({message: res.message, type: 'success'})
                let timer = setTimeout(() => {
                    this.$router.push({name: 'User'})
                }, 500);
            }else{
                  return this.$message.error(res.message)
            }
        },

        handleClose() {
			this.dialogVisible=false 
        }
    }
};
